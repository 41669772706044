import { countries } from 'countries-list';

export const countryPositions = {
  // North America
  CA: { lat: 65, lng: -95, name: 'Canada' },
  US: { lat: 40, lng: -100, name: 'United States' },
  MX: { lat: 23, lng: -102, name: 'Mexico' },
  GL: { lat: 72, lng: -40, name: 'Greenland' },

  // Central America
  GT: { lat: 15.5, lng: -90.25, name: 'Guatemala' },
  BZ: { lat: 17.25, lng: -88.75, name: 'Belize' },
  SV: { lat: 13.83, lng: -88.92, name: 'El Salvador' },
  HN: { lat: 15, lng: -86.5, name: 'Honduras' },
  NI: { lat: 13, lng: -85, name: 'Nicaragua' },
  CR: { lat: 10, lng: -84, name: 'Costa Rica' },
  PA: { lat: 9, lng: -80, name: 'Panama' },

  // Europe
  GB: { lat: 55, lng: -2, name: 'United Kingdom' },
  IE: { lat: 53, lng: -8, name: 'Ireland' },
  FR: { lat: 46, lng: 2, name: 'France' },
  DE: { lat: 51, lng: 10, name: 'Germany' },
  IT: { lat: 43, lng: 12, name: 'Italy' },
  ES: { lat: 40, lng: -3, name: 'Spain' },
  PT: { lat: 39.5, lng: -8, name: 'Portugal' },
  BE: { lat: 50.8, lng: 4, name: 'Belgium' },
  NL: { lat: 52.5, lng: 5.75, name: 'Netherlands' },
  CH: { lat: 47, lng: 8, name: 'Switzerland' },
  AT: { lat: 47.33, lng: 13.33, name: 'Austria' },
  PL: { lat: 52, lng: 20, name: 'Poland' },
  CZ: { lat: 49.75, lng: 15.5, name: 'Czech Republic' },
  SK: { lat: 48.67, lng: 19.5, name: 'Slovakia' },
  HU: { lat: 47, lng: 20, name: 'Hungary' },
  RO: { lat: 46, lng: 25, name: 'Romania' },
  BG: { lat: 43, lng: 25, name: 'Bulgaria' },
  GR: { lat: 39, lng: 22, name: 'Greece' },
  NO: { lat: 62, lng: 10, name: 'Norway' },
  SE: { lat: 62, lng: 15, name: 'Sweden' },
  FI: { lat: 64, lng: 26, name: 'Finland' },
  DK: { lat: 56, lng: 10, name: 'Denmark' },
  IS: { lat: 65, lng: -18, name: 'Iceland' },

  // Asia
  RU: { lat: 65, lng: 100, name: 'Russia' },
  CN: { lat: 35, lng: 105, name: 'China' },
  JP: { lat: 36, lng: 138, name: 'Japan' },
  KR: { lat: 37, lng: 127.5, name: 'South Korea' },
  KP: { lat: 40, lng: 127, name: 'North Korea' },
  IN: { lat: 22, lng: 79, name: 'India' },
  PK: { lat: 30, lng: 70, name: 'Pakistan' },
  AF: { lat: 33, lng: 66, name: 'Afghanistan' },
  IR: { lat: 32, lng: 53, name: 'Iran' },
  IQ: { lat: 33, lng: 44, name: 'Iraq' },
  SA: { lat: 24, lng: 45, name: 'Saudi Arabia' },
  AE: { lat: 24, lng: 54, name: 'UAE' },
  OM: { lat: 21, lng: 57, name: 'Oman' },
  YE: { lat: 15.5, lng: 47.5, name: 'Yemen' },
  SY: { lat: 35, lng: 38, name: 'Syria' },
  TR: { lat: 39, lng: 35, name: 'Turkey' },
  TH: { lat: 15, lng: 101, name: 'Thailand' },
  VN: { lat: 16, lng: 107, name: 'Vietnam' },
  MM: { lat: 21, lng: 96, name: 'Myanmar' },
  LA: { lat: 18, lng: 105, name: 'Laos' },
  KH: { lat: 13, lng: 105, name: 'Cambodia' },
  MY: { lat: 2.5, lng: 112.5, name: 'Malaysia' },
  ID: { lat: -2, lng: 120, name: 'Indonesia' },
  PH: { lat: 13, lng: 122, name: 'Philippines' },

  // Oceania
  AU: { lat: -25, lng: 135, name: 'Australia' },
  NZ: { lat: -41, lng: 174, name: 'New Zealand' },
  PG: { lat: -6, lng: 147, name: 'Papua New Guinea' },
  FJ: { lat: -18, lng: 175, name: 'Fiji' },

  // South America
  AR: { lat: -34, lng: -64, name: 'Argentina' },
  BO: { lat: -17, lng: -65, name: 'Bolivia' },
  BR: { lat: -10, lng: -55, name: 'Brazil' },
  CL: { lat: -30, lng: -71, name: 'Chile' },
  CO: { lat: 4, lng: -72, name: 'Colombia' },
  EC: { lat: -2, lng: -77.5, name: 'Ecuador' },
  GY: { lat: 5, lng: -59, name: 'Guyana' },
  PE: { lat: -10, lng: -76, name: 'Peru' },
  PY: { lat: -23, lng: -58, name: 'Paraguay' },
  SR: { lat: 4, lng: -56, name: 'Suriname' },
  UY: { lat: -33, lng: -56, name: 'Uruguay' },
  VE: { lat: 8, lng: -66, name: 'Venezuela' },

  // Africa
  DZ: { lat: 36, lng: 3, name: 'Algeria' },
  AO: { lat: -12.5, lng: 18.5, name: 'Angola' },
  BJ: { lat: 9.5, lng: 2.25, name: 'Benin' },
  BW: { lat: -22, lng: 24, name: 'Botswana' },
  BF: { lat: 13, lng: -2, name: 'Burkina Faso' },
  BI: { lat: -3.5, lng: 30, name: 'Burundi' },
  CM: { lat: 6, lng: 12, name: 'Cameroon' },
  CV: { lat: 16, lng: -24, name: 'Cape Verde' },
  CF: { lat: 7, lng: 21, name: 'Central African Republic' },
  TD: { lat: 15, lng: 19, name: 'Chad' },
  KM: { lat: -12, lng: 44, name: 'Comoros' },
  CD: { lat: -4, lng: 22, name: 'DR Congo' },
  CG: { lat: -1, lng: 15, name: 'Republic of Congo' },
  CI: { lat: 8, lng: -5, name: "Côte d'Ivoire" },
  DJ: { lat: 11.5, lng: 43, name: 'Djibouti' },
  EG: { lat: 27, lng: 30, name: 'Egypt' },
  GQ: { lat: 2, lng: 10, name: 'Equatorial Guinea' },
  ER: { lat: 15, lng: 39, name: 'Eritrea' },
  ET: { lat: 8, lng: 38, name: 'Ethiopia' },
  GA: { lat: -1, lng: 11.75, name: 'Gabon' },
  GM: { lat: 13.5, lng: -15.5, name: 'Gambia' },
  GH: { lat: 8, lng: -2, name: 'Ghana' },
  GN: { lat: 11, lng: -10, name: 'Guinea' },
  GW: { lat: 12, lng: -15, name: 'Guinea-Bissau' },
  KE: { lat: 1, lng: 38, name: 'Kenya' },
  LS: { lat: -29.5, lng: 28.5, name: 'Lesotho' },
  LR: { lat: 6.5, lng: -9.5, name: 'Liberia' },
  LY: { lat: 25, lng: 17, name: 'Libya' },
  MG: { lat: -20, lng: 47, name: 'Madagascar' },
  MW: { lat: -13.5, lng: 34, name: 'Malawi' },
  ML: { lat: 17, lng: -4, name: 'Mali' },
  MR: { lat: 20, lng: -12, name: 'Mauritania' },
  MU: { lat: -20.3, lng: 57.5, name: 'Mauritius' },
  MA: { lat: 32, lng: -5, name: 'Morocco' },
  MZ: { lat: -18.25, lng: 35, name: 'Mozambique' },
  NA: { lat: -22, lng: 17, name: 'Namibia' },
  NE: { lat: 16, lng: 8, name: 'Niger' },
  NG: { lat: 10, lng: 8, name: 'Nigeria' },
  RW: { lat: -2, lng: 30, name: 'Rwanda' },
  ST: { lat: 1, lng: 7, name: 'São Tomé and Príncipe' },
  SN: { lat: 14, lng: -14, name: 'Senegal' },
  SC: { lat: -4.6, lng: 55.5, name: 'Seychelles' },
  SL: { lat: 8.5, lng: -11.5, name: 'Sierra Leone' },
  SO: { lat: 10, lng: 49, name: 'Somalia' },
  ZA: { lat: -29, lng: 24, name: 'South Africa' },
  SS: { lat: 8, lng: 30, name: 'South Sudan' },
  SD: { lat: 15, lng: 30, name: 'Sudan' },
  SZ: { lat: -26.5, lng: 31.5, name: 'Eswatini' },
  TZ: { lat: -6, lng: 35, name: 'Tanzania' },
  TG: { lat: 8, lng: 1.17, name: 'Togo' },
  TN: { lat: 34, lng: 9, name: 'Tunisia' },
  UG: { lat: 1, lng: 32, name: 'Uganda' },
  ZM: { lat: -15, lng: 30, name: 'Zambia' },
  ZW: { lat: -20, lng: 30, name: 'Zimbabwe' },

  // Additional Asian Countries
  BN: { lat: 4.5, lng: 114.67, name: 'Brunei' },
  BT: { lat: 27.5, lng: 90.5, name: 'Bhutan' },
  TL: { lat: -8.87, lng: 125.73, name: 'Timor-Leste' },
  MV: { lat: 3.2, lng: 73.22, name: 'Maldives' },
  NP: { lat: 28, lng: 84, name: 'Nepal' },
  LK: { lat: 7, lng: 81, name: 'Sri Lanka' },

  // Additional Pacific Islands
  SB: { lat: -9.64, lng: 160.16, name: 'Solomon Islands' },
  VU: { lat: -15.37, lng: 166.96, name: 'Vanuatu' },
  NC: { lat: -20.9, lng: 165.62, name: 'New Caledonia' },
  WS: { lat: -13.76, lng: -172.1, name: 'Samoa' },
  TO: { lat: -21.18, lng: -175.2, name: 'Tonga' },

  // Caribbean
  CU: { lat: 21.5, lng: -77.8, name: 'Cuba' },
  HT: { lat: 19, lng: -72.3, name: 'Haiti' },
  DO: { lat: 18.7, lng: -70.2, name: 'Dominican Republic' },
  JM: { lat: 18.1, lng: -77.3, name: 'Jamaica' },
  BS: { lat: 24.2, lng: -76, name: 'Bahamas' },
  BB: { lat: 13.2, lng: -59.5, name: 'Barbados' },
  TT: { lat: 10.7, lng: -61.2, name: 'Trinidad and Tobago' },
};

export const getCountryPosition = (countryCode, radius = 1.01) => {
  const country = countryPositions[countryCode];
  if (!country) return null;

  const lat = country.lat * (Math.PI / 180);
  const lng = country.lng * (Math.PI / 180);

  const x = radius * Math.cos(lat) * Math.sin(lng);
  const y = radius * Math.sin(lat);
  const z = radius * Math.cos(lat) * Math.cos(lng);

  return [x, y, z];
};

export const getCountryName = (countryCode) => {
  return (
    countryPositions[countryCode]?.name ||
    countries[countryCode]?.name ||
    countryCode
  );
};

export const isValidCountryCode = (countryCode) => {
  return countryCode in countryPositions || countryCode in countries;
};

export const getCountryCoordinates = (countryCode) => {
  const country = countryPositions[countryCode];
  if (!country) return null;
  return { lat: country.lat, lng: country.lng };
};
