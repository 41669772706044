import React, { useState, useEffect } from 'react';
import {
  getAllInvitationRequests,
  createInvitationCode,
  updateInvitationRequestStatus,
} from '../services/firestoreService';
import '../styles/InvitationRequestManagement.css';

function InvitationRequestManagement() {
  const [requests, setRequests] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedFilter, setSelectedFilter] = useState('pending'); // 'all', 'pending', 'approved', 'rejected'
  const [processingId, setProcessingId] = useState(null);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      const allRequests = await getAllInvitationRequests();
      const sortedRequests = allRequests.sort(
        (a, b) => b.createdAt?.toDate?.() - a.createdAt?.toDate?.(),
      );
      setRequests(sortedRequests);
    } catch (err) {
      setError('Failed to fetch invitation requests');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleApprove = async (request) => {
    setProcessingId(request.id);
    try {
      // Generate new invitation code
      const { code } = await createInvitationCode(request.email);

      // Update request status
      await updateInvitationRequestStatus(request.id, 'approved', code);

      // Send email notification
      await sendInvitationEmail(request.email, code);

      // Refresh requests list
      await fetchRequests();
    } catch (err) {
      setError('Failed to approve request');
      console.error(err);
    } finally {
      setProcessingId(null);
    }
  };

  const handleReject = async (request) => {
    setProcessingId(request.id);
    try {
      await updateInvitationRequestStatus(request.id, 'rejected');

      // Send rejection email
      await sendRejectionEmail(request.email);

      await fetchRequests();
    } catch (err) {
      setError('Failed to reject request');
      console.error(err);
    } finally {
      setProcessingId(null);
    }
  };

  const sendInvitationEmail = async (email, code) => {
    // Implementation would depend on your email service
  };

  const sendRejectionEmail = async (email) => {
    // Implementation would depend on your email service
  };

  const filteredRequests = requests.filter((request) =>
    selectedFilter === 'all' ? true : request.status === selectedFilter,
  );

  const getStatusColor = (status) => {
    switch (status) {
      case 'approved':
        return 'status-approved';
      case 'rejected':
        return 'status-rejected';
      case 'pending':
        return 'status-pending';
      default:
        return '';
    }
  };

  if (loading) return <div className="loading-spinner">Loading...</div>;
  if (error) return <div className="error-message">{error}</div>;

  return (
    <div className="invitation-requests-management">
      <div className="management-header">
        <h2>Invitation Requests</h2>
        <div className="filter-controls">
          <button
            className={`filter-btn ${selectedFilter === 'all' ? 'active' : ''}`}
            onClick={() => setSelectedFilter('all')}
          >
            All
          </button>
          <button
            className={`filter-btn ${
              selectedFilter === 'pending' ? 'active' : ''
            }`}
            onClick={() => setSelectedFilter('pending')}
          >
            Pending
          </button>
          <button
            className={`filter-btn ${
              selectedFilter === 'approved' ? 'active' : ''
            }`}
            onClick={() => setSelectedFilter('approved')}
          >
            Approved
          </button>
          <button
            className={`filter-btn ${
              selectedFilter === 'rejected' ? 'active' : ''
            }`}
            onClick={() => setSelectedFilter('rejected')}
          >
            Rejected
          </button>
        </div>
      </div>

      <div className="requests-list">
        {filteredRequests.length === 0 ? (
          <p className="no-requests">No {selectedFilter} requests found</p>
        ) : (
          <div className="requests-grid">
            {filteredRequests.map((request) => (
              <div key={request.id} className="request-card">
                <div
                  className={`status-badge ${getStatusColor(request.status)}`}
                >
                  {request.status}
                </div>
                <div className="request-info">
                  <h3>{request.email}</h3>
                  <div className="request-meta">
                    <div className="meta-item">
                      <div className="meta-label">Name</div>
                      <div className="meta-value">
                        {request.metadata?.name ||
                          request.name ||
                          'Not provided'}
                      </div>
                    </div>
                    <div className="meta-item">
                      <div className="meta-label">Platform</div>
                      <div className="meta-value">
                        {request.metadata?.platform || 'Not specified'}
                      </div>
                    </div>
                    <div className="meta-item">
                      <div className="meta-label">Request Date</div>
                      <div className="meta-value">
                        {request.createdAt?.toDate?.().toLocaleDateString()} at{' '}
                        {request.createdAt?.toDate?.().toLocaleTimeString()}
                      </div>
                    </div>
                    <div className="meta-item">
                      <div className="meta-label">User Agent</div>
                      <div className="meta-value">
                        {request.metadata?.userAgent || 'Not available'}
                      </div>
                    </div>
                  </div>
                  {request.reason && (
                    <div className="request-details">
                      <div className="meta-label">Request Reason</div>
                      <p>{request.reason}</p>
                    </div>
                  )}
                  {request.invitationCode && (
                    <div className="invitation-code">
                      <strong>{request.invitationCode}</strong>
                      <button
                        className="copy-btn"
                        onClick={() =>
                          navigator.clipboard.writeText(request.invitationCode)
                        }
                      >
                        Copy
                      </button>
                    </div>
                  )}
                </div>
                {request.status === 'pending' && (
                  <div className="request-actions">
                    <button
                      onClick={() => handleApprove(request)}
                      className="btn btn-success"
                      disabled={processingId === request.id}
                    >
                      {processingId === request.id
                        ? 'Processing...'
                        : 'Approve'}
                    </button>
                    <button
                      onClick={() => handleReject(request)}
                      className="btn btn-danger"
                      disabled={processingId === request.id}
                    >
                      {processingId === request.id ? 'Processing...' : 'Reject'}
                    </button>
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
}

export default InvitationRequestManagement;
