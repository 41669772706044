import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import {
  doc,
  getDoc,
  collection,
  query,
  where,
  getDocs,
  addDoc,
  serverTimestamp,
  updateDoc,
  arrayRemove,
  arrayUnion,
  increment,
  setDoc,
  writeBatch,
} from 'firebase/firestore';
import {
  FaEnvelope,
  FaPhone,
  FaLinkedin,
  FaGithub,
  FaTwitter,
  FaInstagram,
  FaFacebook,
  FaMapMarkerAlt,
  FaBriefcase,
  FaGraduationCap,
  FaUserPlus,
  FaCommentDots,
  FaBookmark,
  FaRegBookmark,
  FaHeart,
  FaRegHeart,
  FaRegCommentDots,
  FaShare,
  FaLink,
  FaWhatsapp,
} from 'react-icons/fa';
import { motion } from 'framer-motion';
import WorldGlobe from './WorldGlobe';
import { useAuth } from '../contexts/AuthContext';
import { fetchPublicProfile as fetchProfileFromFirestore } from '../services/firestoreService';
import '../styles/publicProfile.css';
import { BADGES } from '../data/badges';

function PublicProfile() {
  const { userId } = useParams();
  const [profile, setProfile] = useState(null);
  const [services, setServices] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedService, setSelectedService] = useState(null);
  const [isBookmarked, setIsBookmarked] = useState(false);
  const [showMessageModal, setShowMessageModal] = useState(false);
  const { currentUser } = useAuth();
  const [isLoved, setIsLoved] = useState(false);
  const [loveCount, setLoveCount] = useState(0);
  const [showShareModal, setShowShareModal] = useState(false);

  useEffect(() => {
    fetchPublicProfile();
    fetchUserServices();
    const fetchLoveCount = async () => {
      try {
        const userDoc = await getDoc(doc(db, 'users', userId));
        if (userDoc.exists()) {
          setLoveCount(userDoc.data().loveCount || 0);
          // Check if current user has loved this profile
          if (currentUser) {
            const lovedByDoc = await getDoc(doc(db, 'lovedProfiles', userId));
            if (lovedByDoc.exists()) {
              setIsLoved(lovedByDoc.data().lovedBy?.includes(currentUser.uid));
            }
          }
        }
      } catch (error) {
        console.error('Error fetching love count:', error);
      }
    };
    fetchLoveCount();
  }, [userId, currentUser]);

  const fetchPublicProfile = async () => {
    setLoading(true);
    setError(null);
    try {
      const profileData = await fetchProfileFromFirestore(userId);
      setProfile({
        ...profileData,
        badges: profileData.badges || [],
      });
    } catch (err) {
      console.error('Error fetching public profile:', err);
      setError('Failed to fetch public profile');
    } finally {
      setLoading(false);
    }
  };

  const fetchUserServices = async () => {
    try {
      const servicesQuery = query(
        collection(db, 'services'),
        where('userId', '==', userId),
      );
      const servicesSnapshot = await getDocs(servicesQuery);
      const servicesList = servicesSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setServices(servicesList);
    } catch (err) {
      console.error('Error fetching user services:', err);
    }
  };

  const renderSkillsCloud = () => {
    const allSkills = Object.entries(profile.skills || {}).reduce(
      (acc, [category, skills]) => {
        return [
          ...acc,
          ...skills.map((skill) => ({
            name: skill,
            level: profile.proficiencyLevels?.[skill] || '1',
            category,
          })),
        ];
      },
      [],
    );

    const getProficiencyLabel = (level) => {
      const levels = {
        1: 'Beginner',
        2: 'Elementary',
        3: 'Intermediate',
        4: 'Advanced',
        5: 'Expert',
      };
      return levels[level] || 'Beginner';
    };

    return (
      <div className="skills-cloud">
        {allSkills.map((skill, index) => (
          <motion.div
            key={index}
            className="skill-item"
            data-level={skill.level}
            title={`${skill.name} - ${getProficiencyLabel(skill.level)}`}
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              delay: index * 0.05,
              type: 'spring',
              stiffness: 100,
            }}
            whileHover={{ scale: 1.1 }}
          >
            {skill.name}
            <div className="skill-proficiency">
              <div className="proficiency-dot"></div>
              <div className="proficiency-dot"></div>
              <div className="proficiency-dot"></div>
              <div className="proficiency-dot"></div>
              <div className="proficiency-dot"></div>
            </div>
          </motion.div>
        ))}
      </div>
    );
  };

  const renderMap = () => (
    <section className="profile-map-section">
      <h2>Countries Served</h2>
      <div className="world-map-container" style={{ height: '500px' }}>
        <WorldGlobe
          selectedCountries={profile.countriesServed || []}
          isInteractive={false}
        />
      </div>
    </section>
  );

  const renderTechPlatformList = (items) => (
    <div className="tech-platform-list">
      {Object.entries(items || {}).map(([category, technologies]) => (
        <div key={category} className="tech-category">
          <h4>{category}</h4>
          {Array.isArray(technologies) ? (
            technologies.map((tech, index) => (
              <span key={index} className="tech-platform-item">
                {tech}
              </span>
            ))
          ) : (
            <span className="tech-platform-item">No items available</span>
          )}
        </div>
      ))}
    </div>
  );

  const renderPortfolio = () => (
    <section className="portfolio-section cyber-frame">
      <h2>
        Portfolio<span className="section-line"></span>
      </h2>
      {profile.portfolioItems && profile.portfolioItems.length > 0 ? (
        <div className="portfolio-grid">
          {profile.portfolioItems.map((item, index) => (
            <div key={index} className="portfolio-item">
              <div className="portfolio-image-container">
                {item.mediaUrl ? (
                  <img
                    src={item.mediaUrl}
                    alt={item.title}
                    className="portfolio-image"
                  />
                ) : (
                  <div className="portfolio-image-placeholder">No Image</div>
                )}
              </div>
              <div className="portfolio-details">
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <p>Tools Used: {item.toolsUsed}</p>
                <p>Results: {item.results}</p>
                {item.projectUrl && (
                  <a
                    href={item.projectUrl}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn-view"
                  >
                    View Project
                  </a>
                )}
              </div>
            </div>
          ))}
        </div>
      ) : (
        <p>No portfolio items available</p>
      )}
    </section>
  );

  const handleBookmark = () => {
    setIsBookmarked(!isBookmarked);
    // TODO: Implement bookmark functionality with backend
  };

  const handleSendMessage = async (e) => {
    e.preventDefault();
    const message = e.target.querySelector('#message').value;
    const senderName = e.target.querySelector('#senderName').value;
    const senderEmail = e.target.querySelector('#senderEmail').value;
    const senderPhone = e.target.querySelector('#senderPhone').value;

    // Validate name length
    if (senderName.trim().length < 2) {
      alert('Please enter your name or company name');
      return;
    }

    // Validate message word count (minimum 25 words)
    const wordCount = message.trim().split(/\s+/).length;
    if (wordCount < 25) {
      alert('Please write a detailed message (minimum 25 words)');
      return;
    }

    try {
      await addDoc(collection(db, 'messages'), {
        from: currentUser?.uid || 'anonymous',
        fromName: senderName.trim(),
        senderEmail: senderEmail.trim(),
        senderPhone: senderPhone.trim(),
        to: userId,
        message: message.trim(),
        createdAt: serverTimestamp(),
        read: false,
      });

      setShowMessageModal(false);
      alert('Message sent successfully!');
    } catch (error) {
      console.error('Error sending message:', error);
      alert('Failed to send message. Please try again.');
    }
  };

  const renderMessageModal = () => (
    <div
      className="message-modal-overlay"
      onClick={() => setShowMessageModal(false)}
    >
      <div className="message-modal" onClick={(e) => e.stopPropagation()}>
        <div className="modal-header">
          <div className="recipient-info">
            <img
              src={profile.profilePictureUrl || '/default-avatar.png'}
              alt={profile.name}
              className="recipient-avatar"
            />
            <div className="recipient-details">
              <h3>Message {profile.name}</h3>
              <span className="recipient-title">{profile.profession}</span>
            </div>
          </div>
          <button
            className="close-btn"
            onClick={() => setShowMessageModal(false)}
          >
            ×
          </button>
        </div>

        <form className="message-form" onSubmit={handleSendMessage}>
          <div className="contact-details">
            <div className="form-group">
              <label htmlFor="senderName">Your Name/Company*</label>
              <input
                type="text"
                id="senderName"
                required
                className="contact-input"
                placeholder="Enter your name or company name"
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderEmail">Your Email*</label>
              <input
                type="email"
                id="senderEmail"
                required
                className="contact-input"
                placeholder="Enter your email address"
              />
            </div>
            <div className="form-group">
              <label htmlFor="senderPhone">
                Your Contact Number (Optional)
              </label>
              <input
                type="tel"
                id="senderPhone"
                className="contact-input"
                placeholder="Enter your contact number"
              />
            </div>
            <p className="contact-note">
              * Required fields to help the recipient get back to you.
            </p>
          </div>

          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea
              id="message"
              placeholder="Write your message here..."
              rows="6"
              required
              className="message-input"
            />
            <div className="character-count">0/500 characters</div>
          </div>

          <div className="message-guidelines">
            <h4>Tips for a great message:</h4>
            <ul>
              <li>Introduce yourself briefly</li>
              <li>Be specific about your requirements</li>
              <li>Mention your timeline if any</li>
              <li>Ask relevant questions</li>
            </ul>
          </div>

          <div className="modal-footer">
            <button type="submit" className="send-btn">
              <FaRegCommentDots /> Send Message
            </button>
            <button
              type="button"
              className="cancel-btn"
              onClick={() => setShowMessageModal(false)}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );

  const handleLove = async () => {
    if (!currentUser) {
      alert('Please sign in to love this profile');
      return;
    }

    try {
      const lovedByRef = doc(db, 'lovedProfiles', userId);
      const userRef = doc(db, 'users', userId);
      const batch = writeBatch(db);

      if (isLoved) {
        batch.update(lovedByRef, {
          lovedBy: arrayRemove(currentUser.uid),
        });
        batch.update(userRef, {
          loveCount: increment(-1),
        });
      } else {
        // Create the document if it doesn't exist
        batch.set(
          lovedByRef,
          {
            lovedBy: arrayUnion(currentUser.uid),
          },
          { merge: true },
        );
        batch.update(userRef, {
          loveCount: increment(1),
        });
      }

      await batch.commit();
      setLoveCount((prev) => (isLoved ? prev - 1 : prev + 1));
      setIsLoved(!isLoved);
    } catch (error) {
      console.error('Error updating love status:', error);
      alert('Unable to update love status. Please try again.');
    }
  };

  const formatDuration = (startMonth, endMonth, startYear, endYear) => {
    // Convert month numbers to names
    const getMonthName = (monthNum) => {
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return months[parseInt(monthNum) - 1];
    };

    // Format the duration string
    if (endMonth && endYear) {
      return `${getMonthName(startMonth)} ${startYear} - ${getMonthName(
        endMonth,
      )} ${endYear}`;
    } else {
      return `${getMonthName(startMonth)} ${startYear} - Present`;
    }
  };

  const renderExperienceTree = () => (
    <section className="sidebar-section experience-tree">
      <h2>Experience Timeline</h2>
      <div className="tree-container">
        {profile.workExperience
          ?.filter((exp) => exp.jobTitle && exp.company)
          .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
          .map((exp, index) => (
            <div key={index} className="tree-node">
              <div className="node-content">
                <div className="node-header">
                  <span className="year">
                    {formatDuration(
                      exp.startMonth,
                      exp.endMonth,
                      exp.startYear,
                      exp.endYear,
                    )}
                  </span>
                  <h3>{exp.jobTitle}</h3>
                </div>
                <div className="node-details">
                  <p className="company">{exp.company}</p>
                  <p className="description">{exp.description}</p>
                </div>
              </div>
              {index < profile.workExperience.length - 1 && (
                <div className="tree-line" />
              )}
            </div>
          ))}
      </div>
    </section>
  );

  const handleShare = async (platform) => {
    const profileUrl = window.location.href;
    const text = `Check out ${profile.name}'s professional profile`;

    switch (platform) {
      case 'copy':
        await navigator.clipboard.writeText(profileUrl);
        alert('Profile link copied to clipboard!');
        break;
      case 'twitter':
        window.open(
          `https://twitter.com/intent/tweet?url=${encodeURIComponent(
            profileUrl,
          )}&text=${encodeURIComponent(text)}`,
        );
        break;
      case 'facebook':
        window.open(
          `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
            profileUrl,
          )}`,
        );
        break;
      case 'linkedin':
        window.open(
          `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            profileUrl,
          )}`,
        );
        break;
      case 'whatsapp':
        window.open(
          `https://wa.me/?text=${encodeURIComponent(text + ' ' + profileUrl)}`,
        );
        break;
      default:
        break;
    }
  };

  const renderBadges = () => {
    if (!profile?.badges?.length) {
      return (
        <section className="sidebar-section badges">
          <h2>Badges</h2>
          <p className="no-badges">No badges earned yet</p>
        </section>
      );
    }

    return (
      <section className="sidebar-section badges">
        <h2>Badges</h2>
        <div className="badges-grid">
          {profile.badges
            .map((badgeId) => {
              const badge = BADGES[badgeId];
              if (!badge) {
                console.warn(`Badge not found for ID: ${badgeId}`);
                return null;
              }

              return (
                <div key={badgeId} className="badge-item">
                  <div className="badge-icon">{badge.icon}</div>
                  <span className="badge-name">{badge.name}</span>
                  <div className="badge-tooltip">
                    <p>{badge.description}</p>
                  </div>
                </div>
              );
            })
            .filter(Boolean)}
        </div>
      </section>
    );
  };

  const renderServices = () => (
    <section className="services-section cyber-frame">
      <h2>
        Services<span className="section-line"></span>
      </h2>
      <div className="services-grid">
        {services.map((service, index) => (
          <motion.div
            key={service.id}
            className="service-card"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.3, delay: index * 0.1 }}
          >
            <div className="service-thumbnail-container">
              {service.imageUrl ? (
                <img
                  src={service.imageUrl}
                  alt={service.title}
                  className="service-thumbnail"
                />
              ) : (
                <div className="service-thumbnail-placeholder">
                  No image available
                </div>
              )}
            </div>
            <div className="service-info">
              <h3>{service.title}</h3>
              <p className="service-description">{service.description}</p>
              <p className="service-price">
                ${service.price}
                {service.pricingType === 'starting_from' && ' (Starting from)'}
              </p>
              <div className="service-card-footer">
                <button
                  className="btn-view-details"
                  onClick={() => setSelectedService(service)}
                >
                  View Details
                </button>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </section>
  );

  if (loading) return <div className="loading">Loading profile...</div>;
  if (error) return <div className="error">Error: {error}</div>;
  if (!profile) return <div className="not-found">Profile not found</div>;

  return (
    <div className="public-profile">
      <div className="profile-header">
        <div
          className="profile-cover"
          style={{
            backgroundImage: `url(${
              profile.coverImageUrl || '/default-cover.jpg'
            })`,
          }}
        >
          <div className="action-row">
            <div className="social-strip">
              {profile.showLinkedIn && profile.linkedin && (
                <a
                  href={profile.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              )}
              {profile.showTwitter && profile.twitter && (
                <a
                  href={profile.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              )}
              {profile.showFacebook && profile.facebook && (
                <a
                  href={profile.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              )}
              {profile.showInstagram && profile.instagram && (
                <a
                  href={profile.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              )}
            </div>

            <div className="profile-actions-floating">
              <button className="action-btn love-btn" onClick={handleLove}>
                {isLoved ? <FaHeart className="loved pulse" /> : <FaRegHeart />}
                <span className="love-count">{loveCount}</span>
              </button>
              <button
                className="action-btn message-btn"
                onClick={() => setShowMessageModal(true)}
                title="Send message"
              >
                <FaRegCommentDots />
              </button>
              <button
                className="action-btn share-btn"
                onClick={() => setShowShareModal(true)}
                title="Share profile"
              >
                <FaShare />
              </button>
            </div>
          </div>
        </div>
        <div className="profile-info">
          <img
            src={profile.profilePictureUrl || '/default-avatar.png'}
            alt={profile.name}
            className="profile-picture"
          />
          <div className="profile-details">
            <h1>{profile.fullName || profile.name}</h1>
            {profile.tagline && <p className="tagline">{profile.tagline}</p>}
            <p className="profession">{profile.profession}</p>
            <p className="location">
              <FaMapMarkerAlt /> {profile.location}
            </p>

            <div className="social-strip">
              {profile.showLinkedIn && profile.linkedin && (
                <a
                  href={profile.linkedin}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaLinkedin />
                </a>
              )}
              {profile.showTwitter && profile.twitter && (
                <a
                  href={profile.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaTwitter />
                </a>
              )}
              {profile.showFacebook && profile.facebook && (
                <a
                  href={profile.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaFacebook />
                </a>
              )}
              {profile.showInstagram && profile.instagram && (
                <a
                  href={profile.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FaInstagram />
                </a>
              )}
            </div>
          </div>
        </div>
      </div>

      <div className="profile-content">
        <div className="profile-main">
          <section className="profile-section business-card">
            <div className="about-section">
              <div className="about-header">
                <h2>About</h2>
                <div className="cyber-line"></div>
              </div>
              <div className="about-content">
                <div className="cyber-frame">
                  <p>{profile.bio}</p>
                </div>
              </div>
            </div>
            <div className="skills-section">
              <div className="about-header">
                <h2>Skills</h2>
                <div className="cyber-line"></div>
              </div>
              {renderSkillsCloud()}
            </div>
          </section>

          {renderMap()}

          {renderServices()}

          <section className="portfolio-section cyber-frame">
            <h2>
              Portfolio<span className="section-line"></span>
            </h2>
            <div className="portfolio-grid">
              {profile.portfolioItems?.map((item, index) => (
                <motion.div
                  key={index}
                  className="portfolio-item"
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <div className="portfolio-image-container">
                    {item.mediaUrl ? (
                      <img
                        src={item.mediaUrl}
                        alt={item.title}
                        className="portfolio-image"
                      />
                    ) : (
                      <div className="portfolio-image-placeholder">
                        No Image
                      </div>
                    )}
                  </div>
                  <div className="portfolio-details">
                    <h3>{item.title}</h3>
                    <p>{item.description}</p>
                    <p>Tools Used: {item.toolsUsed}</p>
                    <p>Results: {item.results}</p>
                    {item.projectUrl && (
                      <a
                        href={item.projectUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="btn btn-view"
                      >
                        View Project
                      </a>
                    )}
                  </div>
                </motion.div>
              ))}
            </div>
          </section>

          <section className="experience-section cyber-frame">
            <h2>Professional Experience</h2>
            <div className="experience-timeline">
              {profile.workExperience
                ?.filter((exp) => exp.jobTitle && exp.company)
                .sort((a, b) => new Date(b.startDate) - new Date(a.startDate))
                .map((exp, index) => (
                  <motion.div
                    key={index}
                    className="experience-item"
                    initial={{ opacity: 0, x: -20 }}
                    animate={{ opacity: 1, x: 0 }}
                    transition={{ duration: 0.3, delay: index * 0.1 }}
                  >
                    <div className="experience-header">
                      <h3 className="experience-title">{exp.jobTitle}</h3>
                      <span className="experience-date">
                        {formatDuration(
                          exp.startMonth,
                          exp.endMonth,
                          exp.startYear,
                          exp.endYear,
                        )}
                      </span>
                    </div>
                    <div className="experience-company">{exp.company}</div>
                    <p className="experience-description">{exp.description}</p>
                  </motion.div>
                ))}
            </div>
          </section>
        </div>

        <div className="profile-sidebar">
          <section className="sidebar-section contact-card">
            <h2>Contact Information</h2>
            {profile.showEmail ? (
              <p>
                <FaEnvelope /> {profile.email}
              </p>
            ) : (
              <p className="contact-private">
                <FaEnvelope /> Contact via message
              </p>
            )}
            {profile.showPhone ? (
              <p>
                <FaPhone /> {profile.phone}
              </p>
            ) : (
              <p className="contact-private">
                <FaPhone /> Contact details private
              </p>
            )}
          </section>

          <section className="sidebar-section languages">
            <h2>Languages</h2>
            <div className="language-list">
              {profile.languages?.map((lang, index) => (
                <div key={index} className="language-item">
                  <span className="language-name">{lang.name}</span>
                  <div className="proficiency-badge">{lang.proficiency}</div>
                </div>
              ))}
            </div>
          </section>

          {profile.availabilityStatus && (
            <section className="sidebar-section availability">
              <h2>Availability</h2>
              {profile.availabilityStatus === 'available' ? (
                <>
                  <div className="availability-badge">
                    <span className="status-dot"></span>
                    Available
                  </div>
                  {profile.hourlyRate && (
                    <div className="rate-display">
                      <div className="rate-info">
                        <span className="rate-value">
                          ${profile.hourlyRate}
                        </span>
                        <span className="rate-label">per hour</span>
                      </div>
                    </div>
                  )}
                  <div className="availability-types">
                    {profile.availabilityTypes?.map((type, index) => (
                      <span key={index} className="type-badge">
                        {type}
                      </span>
                    ))}
                  </div>
                </>
              ) : (
                <div className="availability-badge not-available">
                  <span className="status-dot"></span>
                  Not Available
                </div>
              )}
            </section>
          )}

          <section className="sidebar-section technologies">
            <h2>Technologies</h2>
            {renderTechPlatformList(profile.technologies)}
          </section>

          <section className="sidebar-section platforms">
            <h2>Platforms</h2>
            {renderTechPlatformList(profile.platforms)}
          </section>

          {renderBadges()}
        </div>
      </div>

      {selectedService && (
        <div
          className="service-modal-overlay"
          onClick={() => setSelectedService(null)}
        >
          <div
            className="service-modal-content"
            onClick={(e) => e.stopPropagation()}
          >
            <div className="modal-header">
              <div className="modal-header-content">
                <h2>{selectedService.title}</h2>
                <button
                  className="modal-close-btn"
                  onClick={() => setSelectedService(null)}
                >
                  ×
                </button>
              </div>
            </div>

            <div className="service-stats-container">
              <div className="service-stats-overlay">
                <div className="coming-soon-badge">Coming Soon</div>
              </div>
              <div className="service-stats">
                <div className="stat-item">
                  <span className="stat-value">4.9</span>
                  <span className="stat-label">Rating</span>
                </div>
                <div className="stat-divider"></div>
                <div className="stat-item">
                  <span className="stat-value">{loveCount}</span>
                  <span className="stat-label">Loves</span>
                </div>
                <div className="stat-divider"></div>
                <div className="stat-item">
                  <span className="stat-value">127</span>
                  <span className="stat-label">Reviews</span>
                </div>
                <div className="stat-divider"></div>
                <div className="stat-item">
                  <span className="stat-value">24</span>
                  <span className="stat-label">Orders</span>
                </div>
              </div>
            </div>

            {selectedService.imageUrl && (
              <div className="service-modal-image-container">
                <img
                  src={selectedService.imageUrl}
                  alt={selectedService.title}
                  className="service-modal-image"
                />
                <div className="service-modal-image-overlay"></div>
              </div>
            )}

            <div className="modal-body">
              <div className="service-description-full">
                <p>{selectedService.description}</p>
              </div>

              <div className="service-pricing">
                <h3>Pricing</h3>
                {selectedService.pricingType === 'on_request' ? (
                  <p className="price-tag">Price on request</p>
                ) : (
                  <p className="price-tag">
                    ${selectedService.price}
                    {selectedService.pricingType === 'starting_from' &&
                      ' (Starting from)'}
                  </p>
                )}
              </div>
            </div>

            <div className="modal-footer">
              <button
                onClick={() => setShowMessageModal(true)}
                className="contact-btn"
              >
                Contact Me
              </button>
              {profile.showEmail && (
                <a href={`mailto:${profile.email}`} className="contact-btn">
                  <FaEnvelope /> Email
                </a>
              )}
            </div>
          </div>
        </div>
      )}

      {showMessageModal && renderMessageModal()}

      {showShareModal && (
        <div className="modal-overlay" onClick={() => setShowShareModal(false)}>
          <div className="share-modal" onClick={(e) => e.stopPropagation()}>
            <h3>Share Profile</h3>
            <div className="share-options">
              <button
                onClick={() => handleShare('copy')}
                className="share-option"
              >
                <FaLink />
                <span>Copy Link</span>
              </button>
              <button
                onClick={() => handleShare('twitter')}
                className="share-option"
              >
                <FaTwitter />
                <span>Twitter</span>
              </button>
              <button
                onClick={() => handleShare('facebook')}
                className="share-option"
              >
                <FaFacebook />
                <span>Facebook</span>
              </button>
              <button
                onClick={() => handleShare('linkedin')}
                className="share-option"
              >
                <FaLinkedin />
                <span>LinkedIn</span>
              </button>
              <button
                onClick={() => handleShare('whatsapp')}
                className="share-option"
              >
                <FaWhatsapp />
                <span>WhatsApp</span>
              </button>
            </div>
            <button
              className="close-btn"
              onClick={() => setShowShareModal(false)}
            >
              Close
            </button>
          </div>
        </div>
      )}
    </div>
  );
}

export default PublicProfile;
