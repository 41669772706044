import React, { useState } from 'react';
import {
  FaEnvelope,
  FaPhone,
  FaMapMarkerAlt,
  FaCheckCircle,
  FaPaperPlane,
  FaChevronDown,
  FaLinkedin,
  FaFacebook,
  FaTwitter,
} from 'react-icons/fa';
import '../styles/Contact.css';

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
  });
  const [submitted, setSubmitted] = useState(false);
  const [expandedFaq, setExpandedFaq] = useState(null);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitted(true);
    setFormData({ name: '', email: '', message: '' });
    setTimeout(() => setSubmitted(false), 5000);
  };

  const contactInfo = [
    {
      icon: <FaEnvelope />,
      title: 'Email Us',
      content: {
        link: 'mailto:hello@oecsl.com',
        text: 'hello@oecsl.com',
        detail: 'Available 24/7',
      },
    },
    {
      icon: <FaPhone />,
      title: 'Call Us',
      content: {
        link: 'tel:+94812055252',
        text: '+94 81 205 5252',
        detail: 'Mon-Fri, 9:00 AM - 5:00 PM',
      },
    },
    {
      icon: <FaMapMarkerAlt />,
      title: 'Send a Letter',
      content: {
        text: 'No 675, William Gopallawa Mawatha',
        detail: 'Kandy, Sri Lanka',
      },
    },
  ];

  const socialLinks = [
    {
      icon: <FaLinkedin />,
      url: 'https://linkedin.com/company/oecsl',
      label: 'LinkedIn',
    },
    {
      icon: <FaFacebook />,
      url: 'https://facebook.com/oecsl',
      label: 'Facebook',
    },
    { icon: <FaTwitter />, url: 'https://twitter.com/oecsl', label: 'Twitter' },
  ];

  const faqItems = [
    {
      question: 'How can I join OECSL?',
      answer:
        'Joining OECSL is simple! Register through our website to become a member. Membership is free and gives you access to our community, events, resources, and networking opportunities.',
    },
    {
      question: 'What benefits do members receive?',
      answer:
        'OECSL members enjoy exclusive access to networking events, workshops, mentorship programs, industry resources, and job opportunities. Members can also participate in our skill-sharing initiatives and collaborative projects.',
    },
    {
      question: 'Are your events free to attend?',
      answer:
        'Most of our community events and webinars are free for members. Premium workshops and specialized training sessions may have a nominal fee, with members receiving significant discounts.',
    },
    {
      question: 'How can I volunteer or contribute to OECSL?',
      answer:
        'We welcome volunteers who want to contribute to our community! You can help by organizing events, mentoring others, contributing to our knowledge base, or joining our organizing committee. Contact us through this form or email us to discuss opportunities.',
    },
    {
      question: 'Do you offer support for startups?',
      answer:
        'Yes! OECSL provides various resources for startups, including mentorship, networking opportunities, and access to potential investors. We also organize startup-focused events and workshops throughout the year.',
    },
  ];

  return (
    <div className="contact-page">
      <section className="hero section">
        <div className="container">
          <h1 className="section-title">Get in Touch</h1>
          <p className="subtitle">
            Have questions? Want to collaborate? We&apos;d love to hear from
            you!
          </p>
        </div>
      </section>

      <div className="contact-content container">
        <section className="contact-info">
          <div className="contact-grid">
            {contactInfo.map((info, index) => (
              <div key={index} className="contact-item">
                <span className="contact-icon">{info.icon}</span>
                <h3>{info.title}</h3>
                {info.content.link ? (
                  <p>
                    <a href={info.content.link}>{info.content.text}</a>
                  </p>
                ) : (
                  <p>{info.content.text}</p>
                )}
                <p className="contact-detail">{info.content.detail}</p>
              </div>
            ))}
          </div>
        </section>

        <section className="social-media-section">
          <div className="social-links">
            {socialLinks.map((link, index) => (
              <a
                key={index}
                href={link.url}
                target="_blank"
                rel="noopener noreferrer"
                aria-label={link.label}
              >
                {link.icon}
              </a>
            ))}
          </div>
        </section>

        <div className="form-faq-container">
          <section className="contact-form-section">
            <div className="contact-form-container">
              <div className="form-content">
                <h2 className="section-title">Send Us a Message</h2>
                {submitted ? (
                  <div className="success-message">
                    <FaCheckCircle />
                    <p>
                      Thank you for your message. We&apos;ll get back to you
                      soon!
                    </p>
                  </div>
                ) : (
                  <form onSubmit={handleSubmit}>
                    <div className="form-row">
                      <div className="form-group">
                        <label htmlFor="name">Name</label>
                        <input
                          type="text"
                          id="name"
                          name="name"
                          value={formData.name}
                          onChange={handleChange}
                          required
                        />
                      </div>
                      <div className="form-group">
                        <label htmlFor="email">Email</label>
                        <input
                          type="email"
                          id="email"
                          name="email"
                          value={formData.email}
                          onChange={handleChange}
                          required
                        />
                      </div>
                    </div>
                    <div className="form-group">
                      <label htmlFor="message">Message</label>
                      <textarea
                        id="message"
                        name="message"
                        value={formData.message}
                        onChange={handleChange}
                        required
                      ></textarea>
                    </div>
                    <button type="submit" className="btn btn-primary">
                      <FaPaperPlane /> Send Message
                    </button>
                  </form>
                )}
              </div>
            </div>
          </section>

          <section className="faq-section">
            <h2 className="section-title">FAQ</h2>
            <div className="faq-list">
              {faqItems.map((item, index) => (
                <div
                  key={index}
                  className={`faq-item ${
                    expandedFaq === index ? 'expanded' : ''
                  }`}
                >
                  <button
                    className="faq-question"
                    onClick={() =>
                      setExpandedFaq(expandedFaq === index ? null : index)
                    }
                    aria-expanded={expandedFaq === index}
                  >
                    <span className="question-text">{item.question}</span>
                    <FaChevronDown
                      className={`faq-icon ${
                        expandedFaq === index ? 'rotated' : ''
                      }`}
                    />
                  </button>
                  <div className="faq-answer-wrapper">
                    <div className="faq-answer">{item.answer}</div>
                  </div>
                </div>
              ))}
            </div>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Contact;
